import React from "react";

import { bright_90, increaseBrightness } from "external/helpers/adjustColor";

import CrossIcon from "../../assets/icons/cross.icon";
import Typography from "../typography/index.component";

const AppliedFilter = ({ filter, index, primaryColor, handleRemoveFilter }) => (
  <li
    className="applied-filter"
    key={index}
    style={{ backgroundColor: increaseBrightness(primaryColor, bright_90) }}
  >
    <Typography
      variant="para"
      className="applied-filter-text"
      fontColor={primaryColor}
      weight="bold"
    >
      {filter.title}
    </Typography>
    <CrossIcon
      fill={primaryColor}
      size={{ width: 8, height: 8 }}
      className="remove-filter-icon"
      onClickCallback={() => handleRemoveFilter(filter)}
    />
  </li>
);

export default AppliedFilter;
