import React, { useEffect, useState, useContext } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import deepClone from "external/helpers/deepClone";
import classNames from "classnames";
import { bindActionCreators } from "redux";

import { constructSlug } from "external/helpers/constructSlug";
import upsdkService from "external/services/upsdkService";
import { userLogin, userLogout } from "external/store/actions/auth";
import { likeItem, unlikeItem } from "external/store/actions/likes";
import { setLocationDialog } from "external/store/actions/ui";
import configSelector from "external/store/selectors/configSelector";
import itemSelector from "external/store/selectors/itemSelector";
import likesSelector from "external/store/selectors/likesSelector";
import upsdkSelector from "external/store/selectors/upsdkSelector";

import { BaseContext } from "../../context/BaseContext";
import CurrencyHelper from "external/helpers/currency.helper";
import { translateOptions } from "../../i18n";
import LazyImage from "../LazyImage";
import AppButton from "../button/index.component";
import Counter from "../counter/index.component";
import DietIndicator from "../diet-indicator/index.component";
import FavoriteIndicator from "../favorite-indicator/index.component";
import ItemBadge from "../item-badge/index.component";
import Typography from "../typography/index.component";
import { COLOR_WHITE } from "../../constants/colors.constants";
import useRouteHook from "../../hooks/useRoute.hook";

import "./index.component.scss";

function CarouselItem(props) {
  const BaseConsumer = useContext(BaseContext);
  const [showFavLoding, setFavLoading] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const { historyPush } = useRouteHook();

  // props
  const {
    config,
    upsdk,
    item,
    style,
    likes,
    authToken,
    subLocality,
    selectedStore,
    isMobileView,
  } = props;

  // callbacks
  const { t, likeItem, unlikeItem, setLocationDialog } = props;

  // variables
  const primaryColor = configSelector.getPrimaryColor({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });
  const secondaryTextColor = configSelector.getSecondaryTextColor({ config });
  const cartItems = deepClone(upsdkSelector.getCart({ upsdk })?.items);
  const showItemLikeButton = itemSelector.showItemLikeButton({ config });
  const showFoodTypeIndicator = itemSelector.showFoodTypeIndicator({ config });
  const selectedFontFamily = configSelector.getFontFamily({ config });
  const currency = CurrencyHelper.getCurrencySymbol();
  let cartItem, isLiked;

  if (props.item) {
    cartItem = cartItems?.find((itemOfCart) => itemOfCart?.id === item?.id);
    isLiked = likesSelector.isLiked({ likes, item_id: item.id });
  }

  useEffect(() => {
    let quantity = 0;
    if (!cartItem) {
      setItemCount(quantity);
    } else {
      cartItems.forEach((row) => {
        if (row.id === item.id) {
          quantity = quantity + row.quantity;
        }
      });
      setItemCount(quantity);
    }

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItem]);

  const handleClick = () => {
    const { id, name } = props.item;
    const itemSlug = constructSlug(
      name,
      props.item.raw_data.slug || props.item.slug,
    );
    historyPush(`/item-list/${id}/${itemSlug}`);
  };

  const handleIncrementItemCount = () => {
    if (!subLocality && !selectedStore) {
      setLocationDialog();
      return;
    }
    if (item.has_options) {
      BaseConsumer.showItemCustomization(item);
      return;
    }
    upsdkService.addToCart(item.raw_data);
  };

  const handleDecrementItemCount = () => {
    upsdkService.removeFromCart(item.raw_data);
  };

  const handleLikeItem = () => {
    if (!authToken) {
      BaseConsumer.showLoginDialog();
      return;
    }
    setFavLoading(true);
    setTimeout(() => {
      setFavLoading(false);
    }, 500);
    likeItem(item);
  };

  const handleUnlikeItem = () => {
    if (!authToken) {
      BaseConsumer.showLoginDialog();
      return;
    }
    setFavLoading(true);
    setTimeout(() => {
      setFavLoading(false);
    }, 500);
    unlikeItem(item);
  };

  if (props.item) {
    // Props
    const {
      name,
      diet_type,
      is_best_seller,
      thumbnail,
      likes,
      price,
      raw_data,
      is_featured,
      has_options,
      is_stock_available,
      current_stock,
    } = props.item;
    const showItemPrice = price && price >= 0;

    return (
      <div
        onClick={handleClick}
        className={classNames(["carousel-item-wrapper"])}
      >
        <LazyImage
          className="carousel-item-image"
          src={thumbnail}
          alt={name}
          size={isMobileView ? 180 : 320}
        />
        <ItemBadge
          {...props}
          isFeatured={is_featured}
          isBestSeller={is_best_seller}
        />
        {showItemLikeButton && (
          <FavoriteIndicator
            {...props}
            likes={likes}
            isFavorite={isLiked}
            isLoading={showFavLoding}
            isLoggedIn={authToken ? true : false}
            primaryColor={primaryColor}
            primaryTextColor={primaryTextColor}
            markFavoriteCallback={handleLikeItem}
            markUnfavoriteCallback={handleUnlikeItem}
          />
        )}
        <div className="carousel-item-content">
          <div className="card-title-wrapper">
            <div className="product-name-wrapper">
              {showFoodTypeIndicator && (
                <DietIndicator
                  {...props}
                  dietType={diet_type}
                  size={{ width: 16, height: 16 }}
                />
              )}
              <Typography
                variant="h3"
                weight="bold"
                className="product-name"
                fontColor={primaryTextColor}
              >
                {name}
              </Typography>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="pricing-details-wrapper">
            <div className="pricing-details">
              {showItemPrice ? (
                <Typography
                  variant="h4"
                  weight="bold"
                  fontColor={primaryTextColor}
                  className="discounted-item-price"
                >
                  {currency}
                </Typography>
              ) : null}
              <Typography
                variant="h3"
                weight="semiBold"
                fontColor={primaryTextColor}
                className="discounted-item-price"
              >
                {showItemPrice
                  ? CurrencyHelper.formatNumericOnly(price)
                  : raw_data && raw_data.price_descriptor}
              </Typography>
              {raw_data && raw_data.markup_price && is_stock_available ? (
                <Typography
                  variant="para"
                  weight="regular"
                  className="original-item-price"
                >
                  {CurrencyHelper.formatNumericOnly(raw_data.markup_price)}
                </Typography>
              ) : null}
            </div>
            {showItemPrice && raw_data && raw_data.price_descriptor ? (
              <div
                className="item-quantity"
                dangerouslySetInnerHTML={{
                  __html: `${raw_data.price_descriptor}`,
                }}
                style={{
                  color: secondaryTextColor,
                  fontFamily: selectedFontFamily,
                  fontWeight: "normal !important",
                }}
              />
            ) : null}
          </div>
          <div className="action-right">
            <div className="button-wrapper">
              {!is_stock_available ? (
                <AppButton
                  {...props}
                  variant="contained"
                  className="out-of-stock-cta"
                  buttonColor={"#FACD5D"}
                  onClickCallback={() => {}}
                >
                  <Typography variant="h3" weight="regular">
                    {t("buttons.outOfStock")}
                  </Typography>
                </AppButton>
              ) : itemCount ? (
                <Counter
                  {...props}
                  itemCount={itemCount}
                  primaryColor={COLOR_WHITE}
                  countDecrementCallback={handleDecrementItemCount}
                  countIncrementCallback={handleIncrementItemCount}
                  currentStock={current_stock}
                  fontColor={primaryColor}
                />
              ) : (
                <AppButton
                  {...props}
                  variant="contained"
                  buttonColor={COLOR_WHITE}
                  onClickCallback={handleIncrementItemCount}
                >
                  <Typography
                    variant="h3"
                    weight="bold"
                    fontColor={primaryColor}
                    className="add-to-cart-cta"
                  >
                    {t("buttons.add")}
                  </Typography>
                </AppButton>
              )}
            </div>
            {has_options && is_stock_available && (
              <Typography
                variant="para"
                weight="regular"
                fontColor={primaryColor}
                className="customize-cta"
                onClickCallback={handleIncrementItemCount}
              >
                {t("itemCard.customize")}
              </Typography>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="carousel-item-placeholder-wrapper" style={{ ...style }}>
      <div className="carousel-item-details-placeholder-wrapper">
        <Shimmer>
          <div className="carousel-image-placeholder" />
        </Shimmer>
        <div
          className="carousel-item-content-placeholder"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        >
          <Shimmer>
            <div
              className="carousel-item-title-plaholder"
              style={{
                width: "100%",
                height: 20,
                marginBottom: 20,
                borderRadius: "8px",
              }}
            />
          </Shimmer>
          <Shimmer>
            <div
              className="carousel-item-title-plaholder"
              style={{
                width: "80%",
                height: 20,
                borderRadius: "8px",
              }}
            />
          </Shimmer>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    upsdk: state.upsdk,
    config: state.config,
    likes: state.likes.data,
    userData: state.auth.data,
    subLocality: state.ui.subLocality,
    cart: upsdkSelector.getCart(state),
    selectedStore: upsdkSelector.getStore(state),
    authToken: upsdkSelector.getAuthHeader(state),
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      likeItem: likeItem,
      unlikeItem: unlikeItem,
      userLogin: userLogin,
      userLogout: userLogout,
      setLocationDialog: setLocationDialog,
    },
    dispatch,
  );

export default withRouter(
  withTranslation(
    ["translations"],
    translateOptions,
  )(connect(mapStateToProps, mapDispatchToProps)(CarouselItem)),
);
