import loadScriptTag from "../../helpers/loadScriptTag";
import loadScript from "../../helpers/loadScript";

function init(
  { token, foregroundColor = "#FFF", backgroundColor = "#000" },
  callback = () => console.log("fresh chat initiated"),
) {
  const src = `
  window.fcWidget.init({
  token: "${token}",
  host: "https://wchat.in.freshchat.com",
  config: {
  headerProperty: {
      backgroundColor: '${backgroundColor}',
      foregroundColor: '${foregroundColor}'
    },
    disableEvents: true,
    cssNames: {
      widget: 'fc_frame',
      open: 'fc_open',
      expanded: 'fc_expanded'
    },
    showFAQOnOpen: true,
    hideFAQ: true,
    agent: {
      hideName: false,
      hidePic: false,
      hideBio: false,
    },
    content: {
      placeholders: {
        search_field: 'Search',
        reply_field: 'Reply',
        csat_reply: 'Add your comments here'
      },
      actions: {
        csat_yes: 'Yes',
        csat_no: 'No',
        push_notify_yes: 'Yes',
        push_notify_no: 'No',
        tab_faq: 'Solutions',
        tab_chat: 'Chat',
        csat_submit: 'Submit'
      },
      headers: {
        chat: 'Chat with Us',
        chat_help: 'Reach out to us if you have any questions',
        faq: 'Solution Articles',
        faq_help: 'Browse our articles',
        faq_not_available: 'No Articles Found',
        faq_search_not_available: 'No articles were found for {{query}}',
        faq_useful: 'Was this article helpful?',
        faq_thankyou: 'Thank you for your feedback',
        faq_message_us: 'Message Us',
        push_notification: 'Don\\'t miss out on any replies! Allow push notifications?',
        csat_question: 'Did we address your concerns??',
        csat_yes_question: 'How would you rate this interaction?',
        csat_no_question: 'How could we have helped better?',
        csat_thankyou: 'Thanks for the response',
        csat_rate_here: 'Submit your rating here',
        channel_response: {
          offline: 'We are currently away. Please leave us a message',
          online: {
            minutes: {
              one: "Currently replying in {!time!} minutes ",
              more: "Typically replies in {!time!} minutes"
            },
            hours: {
              one: "Currently replying in under an hour",
              more: "Typically replies in {!time!} hours",
            }
          }
        }
      }
    }
  }
});
`;
  loadScript("https://wchat.freshchat.com/js/widget.js", () => {
    loadScriptTag(src, callback);
  });
}

function updateInfo({ firstName, lastName, phone, email }) {
  if (!window.fcWidget) return;
  window.fcWidget.user.setProperties({
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
  });
}

const freshChatService = {
  init,
  updateInfo,
};

export default freshChatService;
