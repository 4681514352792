import {
  useLocation,
  useHistory,
  NavLink,
  Link,
  useParams,
} from "react-router-dom";
import configSelector from "external/store/selectors/configSelector";

export default function useRouteHook() {
  const config = window?.ROOT_CONFIG || {};
  const subPath = configSelector.getSubPath({ config });
  const { search, pathname, state } = useLocation();
  const params = useParams();
  const history = useHistory();

  const generatePath = (to) => {
    if (to.indexOf("http") === 0) {
      return {
        pathname: to,
      };
    }
    if (subPath) {
      if (to === "/" || to === "") {
        return "/" + subPath + "/" + search;
      }
      return "/" + subPath + to + "/" + search;
    } else {
      if (to === "/") {
        return to + search;
      }
      return to + "/" + search;
    }
  };

  const historyPush = (to, data = {}) => {
    //strip % symbol from "to" as it can cause issues with URL encoding
    to = to.replace(/%/g, "");
    const path = generatePath(to);
    history.push(path, data);
  };

  const historyGoBack = () => {
    history.goBack();
  };

  const CustomNavLink = ({
    children,
    className,
    activeStyle,
    activeClassName,
    to,
    style,
  }) => {
    return (
      <NavLink
        className={className}
        activeStyle={activeStyle}
        style={style}
        activeClassName={activeClassName}
        to={generatePath(to)}
      >
        {children}
      </NavLink>
    );
  };

  const CustomLink = ({ children, to, target }) => {
    return (
      <Link to={generatePath(to)} target={target}>
        {children}
      </Link>
    );
  };

  const getRouterState = () => {
    return state;
  };

  return {
    historyPush,
    historyGoBack,
    pathname,
    params,
    CustomNavLink,
    CustomLink,
    getRouterState,
  };
}
