import React from "react";
import Typography from "../../typography/index.component";
import { bright_90, increaseBrightness } from "external/helpers/adjustColor";

export default function SearchedAddresses({
  primaryTextColor,
  secondaryTextColor,
  primaryColor,
  onLocationSelect,
  addressSuggestions,
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleMouseEnter = (e) => {
    e.target.style.backgroundColor = increaseBrightness(
      primaryColor,
      bright_90,
    );
  };

  const handleMouseLeave = (e) => {
    e.target.style.backgroundColor = "initial";
  };

  return (
    <div className="search-address-content">
      {addressSuggestions.length ? (
        <div className="address-suggestions-wrapper">
          {addressSuggestions.map((data, index) => (
            <AddressOption
              key={index}
              data={data}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              primaryTextColor={primaryTextColor}
              secondaryTextColor={secondaryTextColor}
              onClick={() => onLocationSelect(data)}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}

const AddressOption = ({
  data,
  handleMouseEnter,
  handleMouseLeave,
  primaryTextColor,
  secondaryTextColor,
  onClick = () => {},
}) => (
  <div
    className="address-options"
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    key={data.id}
    onClick={() => onClick(data)}
  >
    <Typography
      variant="h4"
      weight="regular"
      className="address-title"
      fontColor={primaryTextColor}
    >
      {data.structured_formatting.main_text || ""}
    </Typography>
    <Typography
      variant="h4"
      weight="regular"
      className="address"
      fontColor={secondaryTextColor}
    >
      {data.description}
    </Typography>
  </div>
);
