import React, { Fragment, useEffect, useState } from "react";
import RTLSupport from "./styles/rtl-support";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ltrTheme, rtlTheme } from "./styles/theme";
import { BrowserRouter } from "react-router-dom";
import MerakiEditorListener from "./common/meraki-editor-listener";
import { ApolloProvider } from "@apollo/client/react/context/ApolloProvider";
import Routes from "./routes/online-ordering.routes";
import { client } from "external/services/graphql/index";
import { connect } from "react-redux";
import ThemeProvider from "@urbanpiper-engineering/meraki-components/dist/provider";
import configSelector from "external/store/selectors/configSelector";
import upsdkService from "external/services/upsdkService";
import upsdkSelector from "external/store/selectors/upsdkSelector";
import { useTranslation } from "react-i18next";

const initialColors = {
  primaryColor: "#5b21b6",
  secondaryColor: "#6526c9",
  tertiaryColor: "#FACD5D",
  successColor: "#419D3E",
  failureColor: "#FF0000",
  primaryTextColor: "#000000",
  secondaryTextColor: "#303030",
  headerBackgroundColor: "#ffffff",
};

const defaultComponentTheme = {
  fontFamily: "Open Sans",
  colors: initialColors,
  isDarkTheme: false,
};

function App({ orderingType, auth, config, upsdk }) {
  const [theme, setTheme] = useState(ltrTheme);
  const { i18n } = useTranslation();
  const direction = i18n.dir();
  const fontFamily = configSelector.getFontFamily({ config });
  const themeColors = configSelector.getThemeColors({ config });
  const [componentTheme, setComponentTheme] = useState(defaultComponentTheme);

  const [apolloClient, updateApolloClient] = useState(client.getInstance());

  // re-connect socket on user login
  // only if state.auth.data is changed
  useEffect(() => {
    if (auth) {
      updateApolloClient(client.getInstance(true));
    }
  }, [auth]);

  useEffect(() => {
    if (direction === "ltr") {
      setTheme(ltrTheme);
    } else {
      setTheme(rtlTheme);
    }
  }, [direction]);

  useEffect(() => {
    setComponentTheme({
      ...componentTheme,
      fontFamily: fontFamily || "Open Sans",
      colors: themeColors || initialColors,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, fontFamily, themeColors]);

  // set default fulfilmentMode only if currentMode is not set
  useEffect(() => {
    const currentMode = upsdkSelector.getFullfilment({ upsdk });
    const defaultMode = configSelector.getDefaultFulfilmentMode({ config });
    upsdkService.setFulfilmentType(currentMode || defaultMode);
  }, []);

  useEffect(() => {
    const mixpanel = configSelector.getMixPanel({ config });
    if (mixpanel.enable && mixpanel.tokens.length) {
      import("external/services/mixPanel").then((mixPanelService) => {
        const tokens = mixpanel.tokens;
        const configDetails = {
          biz_id: window.ROOT_CONFIG.biz.id,
          biz_name: window.ROOT_CONFIG.biz.name,
        };
        const handlers = mixPanelService.default.init(
          tokens,
          configDetails,
          window.ROOT_CONFIG.biz.env,
        );

        return () => mixPanelService.default.unsubscribe(handlers);
      });
    }
  }, []);

  return (
    <Fragment>
      <RTLSupport>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={componentTheme}>
            <BrowserRouter>
              <ApolloProvider client={apolloClient}>
                <MerakiEditorListener />
                <Routes orderingType={orderingType} />
              </ApolloProvider>
            </BrowserRouter>
          </ThemeProvider>
        </MuiThemeProvider>
      </RTLSupport>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth.data,
  config: state.config,
  upsdk: state.upsdk,
});

export default connect(mapStateToProps)(App);
