import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { translateOptions } from "../../../i18n";
import { bright_90, increaseBrightness } from "external/helpers/adjustColor";

import configSelector from "external/store/selectors/configSelector";
import Typography from "../../../common/typography/index.component";

import "./index.component.scss";

function BrandValueSection(props) {
  // props
  const { config, data } = props;

  const primaryColor = configSelector.getPrimaryColor({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });
  const secondaryTextColor = configSelector.getSecondaryTextColor({ config });

  const BrandValue = ({ values }) => (
    <div className="brand-value">
      <img alt="" className="value-icon" src={values.icon} />
      <Typography
        variant="h1"
        weight="semiBold"
        className="value-title"
        fontColor={primaryTextColor}
      >
        {values.label}
      </Typography>
      <Typography
        variant="h3"
        weight="regular"
        className="value-description"
        fontColor={secondaryTextColor}
      >
        {values.description}
      </Typography>
    </div>
  );

  return (
    <div
      className="brand-value-section-wrapper"
      style={{ backgroundColor: increaseBrightness(primaryColor, bright_90) }}
    >
      <div className="brand-value-container container">
        {data?.values && data.values.length
          ? data.values.map((value) => (
              <BrandValue key={value.id} values={value} />
            ))
          : null}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

export default withRouter(
  withTranslation(
    ["translations"],
    translateOptions,
  )(connect(mapStateToProps, null)(BrandValueSection)),
);
