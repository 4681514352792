import LanguageHelper from "external/helpers/language.helper";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";

// variables
const config = window.ROOT_CONFIG;
const activeLanguage = LanguageHelper.getLanguage(config);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: activeLanguage,
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    backend: {
      loadPath: `/locale/{{lng}}.json`,
    },
    getAsync: true,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    // react i18next special options (optional)
    react: {
      useSuspense: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
  });

/**
 * The 'wait' option tells the translate(...) method not to actually build the component until the language
 * pack is loaded which eliminates the flicker we were seeing.
 * @type {{wait: boolean}}
 */

export const translateOptions = {
  wait: true,
};

export default i18n;
