import React, { useState } from "react";
import classNames from "classnames";

import CircularProgress from "@material-ui/core/CircularProgress";

import { useTranslation } from "react-i18next";

import itemSelector from "external/store/selectors/itemSelector";
import FavouriteContainedIcon from "../../assets/icons/favorite-contained.icon";
import FavouriteOutlinedIcon from "../../assets/icons/favorite-outlined.icon";
import Typography from "../typography/index.component";
import { COLOR_BLACK } from "../../constants/colors.constants";

import "./index.component.scss";

export default function FavoriteIndicator({
  likes,
  isFavorite,
  isLoggedIn,
  isLoading,
  config,
  markFavoriteCallback,
  markUnfavoriteCallback,
}) {
  // variables
  const defaultIconColor = "#CC0000";
  const showLikesCount = itemSelector.showLikesCount({ config });
  const [currentLike, setCurrentLike] = useState(likes || 0);
  const [currentFavorite, setCurrentFavorite] = useState(isFavorite);

  const { i18n } = useTranslation();
  const direction = i18n.dir();

  const handleFavoriteClick = (likeCount) => {
    if (isLoggedIn) {
      setCurrentLike(likeCount + 1);
      setCurrentFavorite(true);
    }
    markFavoriteCallback();
  };

  const handleUnFavoriteClick = (likeCount) => {
    if (isLoggedIn) {
      setCurrentLike(likeCount - 1);
      setCurrentFavorite(false);
    }
    markUnfavoriteCallback();
  };

  const handleClick = (evt, fav, likeCount) => {
    evt.stopPropagation();
    fav ? handleUnFavoriteClick(likeCount) : handleFavoriteClick(likeCount);
  };

  const Loader = () => (
    <CircularProgress
      classes={{ root: "button-loader" }}
      style={{ width: 18, height: 18 }}
    />
  );
  function convertNumberToShorthand(num) {
    const suffixes = ["", "k", "M", "B", "T"];

    let suffixIndex = 0;
    while (num >= 1000 && suffixIndex < suffixes.length - 1) {
      num /= 1000;
      suffixIndex++;
    }
    num = num.toFixed(1);

    if (num.endsWith(".0")) {
      num = num.substring(0, num.length - 2);
    }
    const suffix = suffixes[suffixIndex];
    const result = num + suffix;

    return result;
  }
  const getLikedIcon = (fav) => {
    if (fav)
      return (
        <FavouriteContainedIcon
          fill={defaultIconColor}
          className="favorite-icon"
        />
      );

    return (
      <FavouriteOutlinedIcon
        fill={defaultIconColor}
        className="favorite-icon"
      />
    );
  };

  return (
    <div
      className={classNames("favorite-indicator-wrapper", {
        "icon-position-ltr": direction === "ltr",
        "icon-position-rtl": direction === "rtl",
      })}
    >
      {/* for loading screen */}
      {isLoading && (
        <div className="icon-wrapper">
          <Loader />
        </div>
      )}

      {/* if favourite is selected */}
      {!isLoading && (
        <div
          className="icon-wrapper"
          onClick={(evt) => handleClick(evt, currentFavorite, currentLike)}
        >
          {getLikedIcon(currentFavorite)}
          {showLikesCount && currentLike > 0 && (
            <Typography
              variant="h4"
              weight="regular"
              className="likes-count"
              fontColor={COLOR_BLACK}
            >
              {convertNumberToShorthand(currentLike)}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}
