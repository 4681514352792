import React from "react";
import ResponsiveHelper from "external/helpers/responsive.helper";
import Typography from "../../typography/index.component";
import { useTranslation } from "react-i18next";

const SectionTitle = ({ category, secondaryTextColor, primaryTextColor }) => {
  const { name, main_category_name, is_sub_category, item_count } = category;

  const { t } = useTranslation();
  const screenWidth = window.innerWidth;
  const isMobileView = ResponsiveHelper.isMobile(screenWidth);

  if (!is_sub_category) {
    return (
      <div className="section-heading-wrapper section-heading-wrapper--no-subcategory">
        <Typography
          variant="h1"
          weight="bold"
          className="section-heading"
          fontColor={primaryTextColor}
        >
          {name}
        </Typography>
        <Typography variant="h1" className="section-heading-desc" weight="bold">
          {t("common.totalItems", { total: item_count })}
        </Typography>
      </div>
    );
  }

  return (
    <div className="section-heading-wrapper">
      <Typography
        variant="h1"
        weight="bold"
        className="section-heading"
        fontColor={primaryTextColor}
      >
        {main_category_name}
      </Typography>
      <Typography
        variant="h2"
        weight={isMobileView ? "semiBold" : "bold"}
        className="section-heading section-heading-sub-category"
        fontColor={secondaryTextColor}
      >
        {`${name} (${item_count})`}
      </Typography>
    </div>
  );
};

export default SectionTitle;
