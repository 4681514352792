// eslint-disable-next-line no-unused-vars
export function getMenuLinks(customPages, language = "en") {
  let menuLinks = [];
  const data = customPages.filter((page) => page.addToMenu === true);
  data.forEach((customPage) => {
    menuLinks.push({
      _id: customPage._id,
      name: customPage.name,
      seo: customPage.seo,
      path: customPage.path,
    });
  });
  return menuLinks;
}

export function getAllCustomRoutes(customPages, language) {
  const footerLinks = getFooterLinks(customPages, language);
  const menuLinks = getMenuLinks(customPages, language);
  var uniqueCustomLinks = new Set(
    footerLinks.map((footerLink) => footerLink._id),
  );
  return [
    ...footerLinks,
    ...menuLinks.filter((menuLink) => !uniqueCustomLinks.has(menuLink._id)),
  ];
}

// eslint-disable-next-line no-unused-vars
export function getFooterLinks(customPages, language = "en") {
  let customPagesLinks = [];
  const data = customPages.filter((page) => page.addToFooter === true);
  data.forEach((customPage) => {
    customPagesLinks.push({
      _id: customPage._id,
      name: customPage.name,
      seo: customPage.seo,
      path: customPage.path,
    });
  });

  return customPagesLinks;
}
