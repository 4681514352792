import React, { memo, useRef, useState, useEffect } from "react";
import configSelector from "external/store/selectors/configSelector";
import Typography from "../../../common/typography/index.component";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { translateOptions } from "../../../i18n";
import { LANDING_SCREEN_HERO_BACKGROUND } from "../../../constants/image.constants";
import { bright_70, increaseBrightness } from "external/helpers/adjustColor";

import "./index.component.scss";

function TestimonialSection(props) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const autoScroll = useRef();

  // props
  const { config, data } = props;

  // varialbes
  const isCarouselView = data.values && data.values.length > 1;
  const primaryColor = configSelector.getPrimaryColor({ config });
  const backgroundImage =
    data.backgroundImage || LANDING_SCREEN_HERO_BACKGROUND;

  useEffect(() => {
    // autoScroll.current = setInterval((event)=>{
    //     handleArrowClick(event, 1)
    // }, 5000)
    // eslint-disable-next-line
    return () => clearInterval(autoScroll.current);
  }, []);

  const handleIndicatorClick = (event, index) => {
    setActiveSlideIndex(index);
  };

  const handleArrowClick = (event, index) => {
    if (index > 0) {
      setActiveSlideIndex((current) =>
        current < data.values.length - 1 ? current + 1 : 0,
      );
    } else {
      setActiveSlideIndex((current) =>
        current > 0 ? current - 1 : data.values.length - 1,
      );
    }
  };

  const Testimonial = ({ testimonial, index }) => (
    <div
      className="testimonial"
      style={{ display: activeSlideIndex === index ? "block" : "none" }}
    >
      <div className="author-image-wrapper">
        <img
          src={testimonial.author_img}
          alt="testimonial"
          className="testimonial-author-image"
        />
      </div>
      <div className="testimonial-content">
        <div className="quote-wrapper">
          <span className="quote">❝</span>
          <Typography
            variant="h1"
            weight="regular"
            className="testimonial-message"
            fontColor={"#ffffff"}
          >
            {testimonial.content}
          </Typography>
        </div>
        <Typography
          variant="h3"
          weight="bold"
          className="author-name"
          fontColor={"#ffffff"}
        >
          {testimonial.author_name}
        </Typography>
        <Typography
          variant="h4"
          weight="regular"
          className="author-post"
          fontColor={"#ffffff"}
          style={{ fontSize: 8 }}
        >
          {testimonial.author_position}
        </Typography>
      </div>
    </div>
  );

  const CarouselIndicators = () => (
    <div className="carousel-indicator">
      {data.values.map((indicator, index) => (
        <div
          key={index}
          className="dot"
          style={{
            backgroundColor:
              activeSlideIndex === index
                ? primaryColor
                : increaseBrightness(primaryColor, bright_70),
            border: `1px solid ${primaryColor}`,
          }}
          onClick={(event) => handleIndicatorClick(event, index)}
        />
      ))}
    </div>
  );

  const CarouselNavigators = () => (
    <div className="arrow-wrapper">
      <div
        className="prev-arrow"
        onClick={(event) => handleArrowClick(event, -1)}
        dir="ltr"
      >
        &#10094;
      </div>
      <div
        className="next-arrow"
        onClick={(event) => handleArrowClick(event, 1)}
        dir="ltr"
      >
        &#10095;
      </div>
    </div>
  );

  return (
    <div
      className="testimonial-section-wrapper"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.52)),url(${backgroundImage})`,
      }}
    >
      <div className="testimonial-container container">
        {data.values.map((testimonial, index) => (
          <Testimonial
            testimonial={testimonial}
            key={testimonial.id}
            index={index}
          />
        ))}
        {isCarouselView ? <CarouselNavigators /> : null}
      </div>
      {isCarouselView ? <CarouselIndicators /> : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

export default withRouter(
  withTranslation(
    ["translations"],
    translateOptions,
  )(connect(mapStateToProps, null)(memo(TestimonialSection))),
);
