import React, { useState, useContext, useEffect } from "react";
import CrossIcon from "../../../assets/icons/cross.icon";
import Typography from "../../typography/index.component";
import {
  COLOR_SPUN_PEARL,
  COLOR_WHITE,
} from "../../../constants/colors.constants";
import { useTranslation } from "react-i18next";
import SavedAddresses from "./saved-addresses";
import AppButton from "../../button/index.component";
import { BaseContext } from "../../../context/BaseContext";
import { ADD_ADDRESS_VIEW } from "../../../constants/enums.constants";
import Shimmer from "react-shimmer-effect";
import upsdkService from "external/services/upsdkService";

import "./index.component.scss";

export default function ChooseAddressView({
  primaryColor,
  primaryTextColor,
  secondaryTextColor,
  userData,
  closeAddressViewFlow,
  setSelectedAddress,
  selectedAddress,
  handleFlowComplete,
}) {
  const { t } = useTranslation();
  const guestCheckout = userData && userData.guest_checkout;
  const { setActiveAddressView } = useContext(BaseContext);
  const [deliverableAddresses, setDeliverableAddresses] = useState([]);

  const handleSelectAddress = (data) => {
    setSelectedAddress(data);
    handleFlowComplete();
  };

  const handleAddNewAddress = () => {
    setActiveAddressView(ADD_ADDRESS_VIEW);
  };

  useEffect(() => {
    if (userData && !guestCheckout) {
      upsdkService.getDeliverableAddresses().then((response) => {
        setDeliverableAddresses(response.data.addresses);
      });
    }
  }, [userData, guestCheckout]);

  return (
    <div className="choose-address-wrapper">
      <div className="choose-address-heading">
        <div className="choose-address-heading-left">
          <Typography
            variant="h1"
            weight="bold"
            className="choose-address-heading-text"
            fontColor={primaryTextColor}
          >
            {t("sidebar.chooseAddress")}
          </Typography>
        </div>
        <div className="choose-address-heading-right">
          <CrossIcon
            fill={COLOR_SPUN_PEARL}
            size={{ width: 12, heigth: 12 }}
            className="close-address-drawer-icon"
            onClickCallback={closeAddressViewFlow}
          />
        </div>
      </div>
      <div className="choose-address-content">
        {deliverableAddresses.length > 0 ? (
          deliverableAddresses.map((address) => (
            <SavedAddresses
              key={address.id}
              address={address}
              primaryColor={primaryColor}
              primaryTextColor={primaryTextColor}
              secondaryTextColor={secondaryTextColor}
              handleSelectAddress={handleSelectAddress}
              isActive={address.id === selectedAddress?.id}
            />
          ))
        ) : (
          <>
            {[1, 2, 3, 4, 5].map(() => (
              <div className="shimmer-wrapper">
                <Shimmer>
                  <div className="shimmer-address-one" />
                </Shimmer>
                <Shimmer>
                  <div className="shimmer-address-two" />
                </Shimmer>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="choose-address-footer">
        <AppButton
          variant="contained"
          className="save-button"
          buttonColor={primaryColor}
          onClickCallback={handleAddNewAddress}
        >
          <Typography
            variant="h3"
            weight="semiBold"
            className="save-button-text"
            fontColor={COLOR_WHITE}
          >
            {t("checkout.addNewAddress")}
          </Typography>
        </AppButton>
      </div>
    </div>
  );
}
