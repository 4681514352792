import React from "react";
import Typography from "../../typography/index.component";
import { increaseBrightness, bright_90 } from "external/helpers/adjustColor";
import AddressHomeIcon from "../../../assets/icons/address-home.icon";
import AddressWorkIcon from "../../../assets/icons/address-work.icon";
import AddressOtherIcon from "../../../assets/icons/address-others.icon";

export default function SavedAddresses({
  address,
  primaryColor,
  secondaryTextColor,
  isActive,
  handleSelectAddress,
}) {
  const { address_1, sub_locality, tag } = address;

  const renderIcons = () => {
    switch (tag) {
      case "home":
        return (
          <AddressHomeIcon fill={increaseBrightness(primaryColor, bright_90)} />
        );
      case "work":
        return (
          <AddressWorkIcon fill={increaseBrightness(primaryColor, bright_90)} />
        );
      default:
        return (
          <AddressOtherIcon
            fill={increaseBrightness(primaryColor, bright_90)}
          />
        );
    }
  };

  return (
    <div
      className="saved-address"
      onClick={() => handleSelectAddress(address)}
      style={isActive ? { backgroundColor: "#f7f7f7" } : {}}
    >
      <div className="icon-heading-wrapper">
        {renderIcons()}
        <Typography variant="h4" weight="bold" className="saved-address-tag">
          {tag}
        </Typography>
      </div>
      <div className="addressline-wrapper">
        <Typography
          variant="h4"
          weight="regular"
          className="saved-address-sublocality"
          fontColor={secondaryTextColor}
        >
          {address_1}, {sub_locality}
        </Typography>
      </div>
    </div>
  );
}
