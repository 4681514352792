/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-case-declarations */
import clone from "../../helpers/deepClone";
import {
  FETCH_CATALOGUE_ERROR,
  FETCH_CATALOGUE_PENDING,
  FETCH_CATALOGUE_SUCCESS,
  UPDATE_CATALOGUE,
  UPDATE_CATALOGUE_FILTERS,
  UPDATE_CATALOGUE_PENDING,
} from "../actions/catalogue";

const initialState = {
  pending: false,
  categories: null,
  listData: null,
  error: null,
  updateCataloguePending: false,
  options: null,
  appliedOptions: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CATALOGUE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CATALOGUE_SUCCESS:
      const fetchedCategories = action.payload.categories;
      let list = clone(fetchedCategories);
      if (fetchedCategories.length) {
        list = createListData(list);
      }

      return {
        ...state,
        pending: false,
        categories: action.payload.categories,
        listData: list,
        error: null,
      };
    case FETCH_CATALOGUE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        categories: null,
        listData: null,
        options: null,
        appliedOptions: null,
      };

    case UPDATE_CATALOGUE_PENDING:
      return {
        ...state,
        updateCataloguePending: true,
      };

    case UPDATE_CATALOGUE:
      const categories = clone(state.categories);
      let ld = clone(state.listData);

      for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === action.payload.category_id) {
          categories[i].items = action.payload.items || [];
          break;
        } else {
          const subCategories = categories[i]?.sub_categories || [];
          for (let j = 0; j < subCategories.length; j++) {
            if (subCategories[j].id === action.payload.category_id) {
              subCategories[j].items = action.payload.items || [];
              break;
            }
          }
        }
      }

      // update list data
      const foundCategory = ld.find(
        (category) => category.id === action.payload.category_id,
      );
      foundCategory.items = action.payload.items;

      return {
        ...state,
        updateCataloguePending: false,
        listData: ld,
      };

    case UPDATE_CATALOGUE_FILTERS: {
      const { appliedOptions, options } = action.payload;
      return {
        ...state,
        appliedOptions,
        options,
      };
    }

    default:
      return state;
  }
}

export function createListData(data) {
  return data.reduce((acc, category) => {
    if (category.sub_categories && category.sub_categories.length) {
      category.sub_categories.forEach((subCategory) => {
        subCategory.main_category_id = category.id;
        subCategory.main_category_name = category.name;
        subCategory.slug = category.slug + "/" + subCategory.slug;
        subCategory.is_sub_category = true;
        acc.push(subCategory);
      });
    } else {
      acc.push(category);
    }
    return acc;
  }, []);
}
