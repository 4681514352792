import dayjs from "dayjs";
import { getOffsetTime } from "./getOffsetTime";

export function getOpeningClosingForDate({
  fulfillmentType,
  date,
  store,
  biz,
}) {
  /**
   * cannot use a day name here, instead we need to use a number, because day names
   * can change according to the activeLanguage and may cause unwanted behaviour of date and time slots.
   * ex: tuesday(english) and martes(spanish) both are 2 day of the week.
   *
   * We can rather use position of the day in the week
   * and translate it to english to implement the logic.
   */
  const weekArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let dayOfTheWeek = dayjs(date).day();

  const slots = store.time_slots || biz.time_slots || [];

  let filteredSlots = slots?.filter(
    (slot) => slot?.day === weekArray[dayOfTheWeek],
  );

  if (!filteredSlots || !filteredSlots.length) {
    return undefined;
  }
  let offset = getOffsetTime({ fulfillmentType, store, biz });

  let openingArray = filteredSlots[0].start_time.split(":");
  let closingArray =
    filteredSlots[filteredSlots.length - 1].end_time.split(":");

  let opening_time = date
    .clone()
    .set("hour", openingArray[0])
    .set("minute", openingArray[1]);
  let closing_time = date
    .clone()
    .set("hour", closingArray[0])
    .set("minute", closingArray[1]);

  // to test for all other conditions
  // opening_time = opening_time.subtract(14, "h")
  // closing_time = closing_time.subtract(14, "h")

  let startTime = opening_time.clone().add(offset, "milliseconds");
  let endTime = closing_time.clone();

  // Normalising the delivery start time to the provided date as the offset time can be more than 24 hours
  let deliveryStartTime = date.clone();

  // deliveryStartTime.set({
  //   hour: startTime.hour(),
  //   minute: startTime.minute(),
  //   second: startTime.second(),
  //   millisecond: 0,
  // });
  deliveryStartTime = deliveryStartTime
    .set("hour", startTime.hour())
    .set("minute", startTime.minute())
    .set("second", startTime.second());
  // Normalising the delivery closing time to the provided date as the offset time can be more than 24 hours
  let deliveryClosingTime = date
    .clone()
    .set("hour", endTime.hour())
    .set("minute", endTime.minute())
    .set("second", endTime.second());

  if (!date.isAfter()) {
    if (dayjs(date).isAfter(closing_time)) {
      deliveryStartTime = deliveryStartTime.add(1, "day");
    } else if (dayjs(date).isAfter(opening_time)) {
      deliveryStartTime = dayjs().add(offset, "milliseconds");
    }
  }

  return {
    openingTime: opening_time,
    closingTime: closing_time,
    startTime: deliveryStartTime,
    endTime: deliveryClosingTime,
  };
}
