import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BaseContext } from "../../context/BaseContext";
import CrossIcon from "../../assets/icons/cross.icon";
import className from "classnames";
import configSelector from "external/store/selectors/configSelector";

import "./index.component.scss";

export default function PGDialog(props) {
  const { open, close, children, loading, config } = props;

  const { isMobileView } = useContext(BaseContext);
  const primaryColor = configSelector.getPrimaryColor({ config });

  return (
    <Dialog
      open={open}
      onClose={() => close()}
      fullScreen={isMobileView}
      disableScrollLock={true}
      keepMounted
      id="pg-service-dialog"
    >
      <div className="pg-modal-form">
        <div className="dialog-header">
          {!loading && (
            <div className="pg-dialog-icon">
              <button onClick={() => close()}>
                <CrossIcon fill="#A3A3A4" size={{ width: 16, heigth: 16 }} />
              </button>
            </div>
          )}
        </div>
        <div className={className("dialog-content", { loading })}>
          {loading && <CircularProgress color={primaryColor} />}
          <div
            id="pg-modal-helper"
            dangerouslySetInnerHTML={{ __html: children }}
          />
        </div>
      </div>
    </Dialog>
  );
}
