import React, { memo, useEffect, useState, useContext, lazy } from "react";
import fetchCatalogue from "external/store/actions/catalogue";
import RecommendedItem from "./recommended-item/index.component";
import upsdkSelector from "external/store/selectors/upsdkSelector";
import configSelector from "external/store/selectors/configSelector";
import Typography from "../../../common/typography/index.component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { translateOptions } from "../../../i18n";
import { ZIGZAG_SEPARATOR } from "../../../constants/image.constants";
import { BaseContext } from "../../../context/BaseContext";
import graphqlService from "external/services/graphql/graphqlService";

import "./index.component.scss";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

const Swiper = lazy(() => import("swiper/react/swiper-react"));
const SwiperSlide = lazy(() => import("swiper/react/swiper-slide"));

const Navigation = lazy(() => import("swiper/modules/navigation/navigation"));
const Pagination = lazy(() => import("swiper/modules/pagination/pagination"));

function RecommendedSection(props) {
  const [recommended, setRecommended] = useState([]);
  const [loading, setLoading] = useState(true);

  // props
  const { config, categories, selectedStore, fulfillmentType } = props;
  const BaseConsumer = useContext(BaseContext);
  const { isMobileView } = BaseConsumer;

  // callbacks
  const { t, fetchCatalogue } = props;

  // varialbes
  const location_id = selectedStore ? selectedStore.id : null;
  const secondaryColor = configSelector.getSecondaryColor({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });

  useEffect(() => {
    fetchCatalogue();
    // eslint-disable-next-line
  }, [location_id]);

  useEffect(() => {
    setLoading(true);
    graphqlService
      .getRecommendedItems(null, fulfillmentType)
      .then((response) => {
        if (response && response.length > 0) {
          setRecommended(response);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location_id, categories, fulfillmentType]);

  if (!loading && !recommended.length) {
    return null;
  }

  return (
    <div dir="ltr" className="recommended-section-wrapper">
      <div className="recommended-items-container container">
        <Typography
          variant="h1"
          weight="semiBold"
          className="section-title"
          fontColor={secondaryColor}
        >
          {t("common.recommendationItems")}
        </Typography>

        <img src={ZIGZAG_SEPARATOR} alt="separator" className="separator" />

        <div className="recommended-item-list ">
          <Swiper
            slidesPerView={isMobileView ? 1 : 3}
            spaceBetween={30}
            slidesPerGroup={3}
            loop={true}
            loopFillGroupWithBlank={false}
            pagination={false}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {recommended.map((item, index) => (
              <SwiperSlide key={index}>
                <RecommendedItem
                  {...props}
                  primaryTextColor={primaryTextColor}
                  item={item}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
    selectedStore: upsdkSelector.getStore(state),
    categories: state.catalogue?.listData ? state.catalogue.listData : [],
    fulfillmentType: upsdkSelector.getFullfilment(state),
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCatalogue: fetchCatalogue,
    },
    dispatch,
  );

export default withRouter(
  withTranslation(
    ["translations"],
    translateOptions,
  )(connect(mapStateToProps, mapDispatchToProps)(memo(RecommendedSection))),
);
