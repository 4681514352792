export function getSearchHistory() {
  const searchedLocations = localStorage.getItem("locationHistory");

  return JSON.parse(searchedLocations);
}

export function setSearchHistory(searchAddress) {
  let savedLocations = JSON.parse(localStorage.getItem("locationHistory"));

  if (!savedLocations) {
    savedLocations = [];
  }

  const addressIndex = savedLocations.findIndex(
    (address) => address.place_id === searchAddress.place_id,
  );

  if (addressIndex === -1) {
    savedLocations.unshift(searchAddress);
    // Maintains only 5 recent addresses
    if (savedLocations.length > 5) {
      savedLocations.length = 5;
    }
  } else {
    console.log("Address already exists");
  }

  localStorage.setItem("locationHistory", JSON.stringify(savedLocations));
}
