import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { bright_90, increaseBrightness } from "external/helpers/adjustColor";
import { showNotification } from "../../helpers/utils";
import upsdkService from "external/services/upsdkService";
import configSelector from "external/store/selectors/configSelector";

import AppButton from "../../common/button/index.component";
import InputField from "../fields/input-field/index.component";
import ServerError from "../server-error/index.component";
import Typography from "../../common/typography/index.component";
import "./index.component.scss";

export default function Form({ config, componentData, t }) {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(undefined);

  const { register, handleSubmit, errors, reset } = useForm({ mode: "onBlur" });
  // const { t } = useTranslation();
  const primaryColor = configSelector.getPrimaryColor({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });

  const onSubmit = (data) => {
    data.subject = componentData.title || "Form";
    data.form_type = data.subject.toLowerCase().replaceAll(" ", "_");
    setLoading(true);
    upsdkService
      .submitForm(data)
      .then((resp) => {
        if (resp.status === "success") {
          showNotification(t("form.successMessage"), "success");
          setFormError(null);
          reset();
        } else {
          setFormError(t("form.failureMessage"));
        }
      })
      .catch((e) => {
        const message = e.response?.data?.message;
        setFormError(message || t("form.failureMessage"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="generic-form-component-wrapper">
      <div
        style={{ backgroundColor: componentData.backgroundColor || "#cbcbcd" }}
        className="component-header-bg"
      />
      <div className="generic-form-component">
        <div className="dialog-header">
          <Typography
            variant="h1"
            weight="bold"
            className="form-heading"
            fontColor={primaryTextColor}
          >
            {componentData.title || "Form"}
          </Typography>
        </div>
        <div className="dialog-content">
          <form onSubmit={handleSubmit(onSubmit)}>
            {componentData.fields.map((field, i) => (
              <InputField
                key={i}
                refForm={
                  field.required
                    ? register({ required: "This field is required" })
                    : register()
                }
                type={field.type}
                name={field.label}
                errors={errors}
                label={
                  <Typography
                    variant="h4"
                    weight="bold"
                    fontColor={primaryTextColor}
                    isRequired={field.required}
                  >
                    {field.label}
                  </Typography>
                }
                primaryTextColor={primaryTextColor}
                iconBackgroundColor={increaseBrightness(
                  primaryColor,
                  bright_90,
                )}
                helperText={errors[field.label] && errors[field.label].message}
              />
            ))}
          </form>
        </div>

        {formError && <ServerError message={formError} />}

        <div className="dialog-footer">
          <AppButton
            fullWidth
            variant="contained"
            className="form-submit-button"
            isLoading={loading}
            buttonColor={primaryColor}
            onClickCallback={handleSubmit(onSubmit)}
          >
            <Typography
              variant="h2"
              weight="bold"
              className="form-submit-button-text"
            >
              {t("form.submit")}
            </Typography>
          </AppButton>
        </div>
      </div>
    </div>
  );
}
