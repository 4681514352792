import { createTheme } from "@material-ui/core/styles";
import configSelector from "external/store/selectors/configSelector";

//variables
const config = window.ROOT_CONFIG;
const selectedFontFamily = configSelector.getFontFamily({ config });

export const ltrTheme = createTheme({
  direction: "ltr",
  primary: "red",
  typography: {
    useNextVariants: true,
    fontSize: 13,
    fontFamily: [selectedFontFamily, "Open Sans"].join(","),
  },
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiPaper: {
    elevation1: {
      boxShadow: "none",
    },
    elevation2: {
      boxShadow: "none",
    },
  },
  MuiInput: {
    input: {
      fontSize: "13px",
    },
  },
  MuiTab: {
    root: {
      minWidth: "100px",
    },
  },
  MuiPrivateTabScrollButton: {
    root: {
      opacity: 0.5,
    },
  },
});

export const rtlTheme = createTheme({
  direction: "rtl",
  primary: "red",
  typography: {
    useNextVariants: true,
    fontSize: 13,
    fontFamily: [selectedFontFamily, "Open Sans"].join(","),
  },
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiPaper: {
    elevation1: {
      boxShadow: "none",
    },
    elevation2: {
      boxShadow: "none",
    },
  },
  MuiInput: {
    input: {
      fontSize: "13px",
    },
  },
  MuiTab: {
    root: {
      minWidth: "100px",
    },
  },
  MuiPrivateTabScrollButton: {
    root: {
      opacity: 0.5,
    },
  },
});
