function getMenuPage({ config }) {
  const menuPage = config.theme.pages?.find((page) => page?._id === "menu");
  return menuPage;
}

function getOrderHistoryPage({ config }) {
  const orderHistoryPage = config.theme.pages?.find(
    (page) => page?._id === "order_history",
  );
  return orderHistoryPage;
}

function getItemDetailPage({ config }) {
  const itemDetailPage = config.theme.pages?.find(
    (page) => page?._id === "item_detail",
  );
  return itemDetailPage;
}

function getCheckoutPage({ config }) {
  const checkoutPage = config.theme.pages?.find(
    (page) => page?._id === "checkout",
  );
  return checkoutPage;
}

function getFavouritesPage({ config }) {
  const favouritePage = config.theme.pages?.find(
    (page) => page?._id === "favourite",
  );
  return favouritePage;
}

function getReferEarnPage({ config }) {
  const referEarnPage = config.theme.pages?.find(
    (page) => page?._id === "refer_earn",
  );
  return referEarnPage;
}

function getProfilePage({ config }) {
  const profilePage = config.theme.pages?.find(
    (page) => page?._id === "profile",
  );
  return profilePage;
}

function getCartPage({ config }) {
  const cartPage = config.theme.pages?.find((page) => page?._id === "cart");
  return cartPage;
}

function getStoreLocatorPage({ config }) {
  const storeLocatorPage = config.theme.pages?.find(
    (page) => page?._id === "store_locator",
  );
  return storeLocatorPage;
}

function getOrderSuccessPage({ config }) {
  const orderSuccessPage = config.theme.pages?.find(
    (page) => page?._id === "order_success",
  );
  return orderSuccessPage;
}

function getCouponsPage({ config }) {
  const couponsPage = config.theme.pages?.find(
    (page) => page?._id === "coupons",
  );
  return couponsPage;
}

function getWalletPage({ config }) {
  const walletPage = config.theme.pages?.find((page) => page?._id === "wallet");
  return walletPage;
}

function getPageNotFoundPage({ config }) {
  const pageNotFoundPage = config.theme.pages?.find(
    (page) => page?._id === "page_not_found",
  );
  return pageNotFoundPage;
}

function getLandingPage({ config }) {
  const landingPage = config?.theme?.pages?.find(
    (page) => page?._id === "landing_screen",
  );
  return landingPage;
}

const pageSelector = {
  getMenuPage,
  getCartPage,
  getProfilePage,
  getCheckoutPage,
  getReferEarnPage,
  getFavouritesPage,
  getItemDetailPage,
  getOrderHistoryPage,
  getStoreLocatorPage,
  getOrderSuccessPage,
  getCouponsPage,
  getWalletPage,
  getPageNotFoundPage,
  getLandingPage,
};

export default pageSelector;
